import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Carousel from 'common/carousels/Carousel'
import { paginatedSmallSlides } from 'common/carousels/configurations'
import MaybeDraft from 'common/indicators/Draft'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import StyledHeadline from 'common/text/Headline'
import Html from 'common/text/Html'
import { CONTENT_TYPE_UNIT, FEATURE_FLAG_CONTENT_CAROUSEL } from 'core/consts'
import { includes } from 'fp/arrays'
import { get } from 'fp/objects'
import withContent from 'hoc/withContent'
import useToggleState from 'hooks/useToggleState'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { contentStateIsDraft } from 'selectors/content'
import { getCurrentUser } from 'selectors/users'
// import CourseLinks from '../CourseLinks'
import ItemRenderer from './Carousel/ItemRenderer'
import ChapterCard from './ChapterCard'

const Unit = withContent(
  ({ content }) => {
    const demoLevel = compose(
      includes(FEATURE_FLAG_CONTENT_CAROUSEL),
      get('featureFlags'),
      useSelector,
    )(getCurrentUser)

    const isLive = !demoLevel

    const isUnit = content.contentSubType !== 'supplemental'

    const isDraft = useSelector(contentStateIsDraft(content))

    const [showMore, toggleShowMore] = useToggleState(false)

    const contentNameSplit = content.name.split(':')

    const list = content.children
    const isSmScreen = useContainerQuery().down('lg')
    const isXlScreen = useContainerQuery().up('xl')

    const isCourseLarge = Boolean(
      (isSmScreen && content.childrenCount > 2) ||
        (isXlScreen && content.childrenCount > 4) ||
        content.childrenCount > 3,
    )

    const showLessList = () => {
      if (isSmScreen) return list.slice(0, 2)
      if (isXlScreen) return list.slice(0, 4)
      return list.slice(0, 3)
    }

    const handleClick = () => {
      toggleShowMore()
    }

    const contentName = () => {
      const titles = [
        'Course Introduction',
        'Mid-Course Project',
        'End-of-Course Project',
      ]
      const isCourseBreak =
        titles.includes(contentNameSplit[0]) || !contentNameSplit[1]

      return (
        <Box
          alignContent="center"
          display="flex"
          variant="variant-with-bold-fontWeight"
          paddingBottom={2}>
          <StyledHeadline
            title={`${contentNameSplit[0]}${isCourseBreak ? '' : ':'}`}
            variant="course-header"
            weight={isCourseBreak ? 700 : 400}
            marginRight={0.6}
          />
          {Boolean(contentNameSplit[1]) && (
            <StyledHeadline
              title={contentNameSplit[1]}
              variant="course-header"
              weight={700}
            />
          )}
        </Box>
      )
    }

    return (
      <Container data-draft={isDraft ? 'true' : 'false'}>
        <Box
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="wrap"
          display="flex"
          margin={{ top: 0, right: 0, bottom: 2, left: 0 }}>
          <Stack
            flexDirection="column"
            marginRight={{ top: 0, right: 2, bottom: 0, left: 0 }}
            display="flex">
            <Typography
              sx={{ fontWeight: 700, marginRight: 0.6 }}
              variant="course-header">
              {contentName()}

              <MaybeDraft content={content} />
            </Typography>

            <Typography
              mb={0}
              variant="paragraph">
              <Html
                body={content.data.caption}
                substituteInlineBlocks
                sx={{ marginBottom: 1.4 }}
              />
            </Typography>
          </Stack>

          {Boolean(isLive && isUnit && isCourseLarge) && (
            <Button
              aria-label={
                showMore
                  ? `Show Less, ${contentNameSplit[0]}`
                  : `Show All (${content.childrenCount}), ${contentNameSplit[0]}`
              }
              color="primary"
              onClick={handleClick}
              size="small"
              variant="toggle-show-hide">
              {showMore ? 'Show Less' : `Show All (${content.childrenCount})`}
            </Button>
          )}
        </Box>

        {/**
         * Temporary hack, for sales purposes
         */}
        {isLive ? (
          <Grid
            container
            spacing={3}>
            {(showMore ? list : showLessList()).map(item => (
              <>
                <Grid
                  item
                  key={item + item.id}
                  lg={4}
                  md={6}
                  sm={12}
                  xl={3}>
                  <ChapterCard
                    contentId={item.id}
                    key={item.id}
                  />
                </Grid>
                {content.name === 'Course Introduction' && (
                  <Grid
                    item
                    key={item.id}
                    lg={8}
                    md={6}
                    sm={12}
                    xl={9}>
                    {/* <CourseLinks /> */}
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        ) : (
          <Carousel configuration={paginatedSmallSlides}>
            {content.children.map(item => (
              <ItemRenderer
                contentId={item.id}
                key={item + item.id}
              />
            ))}
          </Carousel>
        )}
      </Container>
    )
  },
  { contentType: CONTENT_TYPE_UNIT },
)

export default Unit
