import Stack from '@mui/material/Stack'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import { contentShape } from 'core/shapes'
import withAbilityCheck from 'hoc/withAbilityCheck'
import CardFooter from 'hss/views/Card/CardFooter'
import ContentRestrictionControls from 'hss/views/Curriculum/ContentRestrictionControls'
import { rem } from 'styling/theming/base/mixins'
import Actions from './Actions'
import ChapterAssignmentLink from './ChapterAssignmentLink'

const Footer = withAbilityCheck(
  ({ content }) => (
    <CardFooter
      padding={rem(1.6, 2.4, 3.2)}
      sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <Actions content={content} />
      <Stack
        direction="row"
        justifyContent="space-between"
        width="auto">
        <ChapterAssignmentLink content={content} />

        <ContentRestrictionControls content={content} />
      </Stack>
    </CardFooter>
  ),
  ABILITY_ASSIGNMENT_CREATION,
)

Footer.propTypes = {
  content: contentShape.isRequired,
}

export default Footer
