import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import type { Theme as MuiTheme } from '@mui/material/styles'
import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { equals, get, omit } from 'fp/objects'
import withProps from 'hoc/withProps'
import { compose } from 'redux'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { importantPx, rem } from 'styling/theming/base/mixins'
import Scaffold from './Scaffold'

import type { MUIStyledCommonProps } from '@mui/system'
import type { Theme } from 'styling/theming/@types/custom'

type GenericWrapperProps = MUIStyledCommonProps<MuiTheme> & {
  children?: React.ReactNode
}

type GenericWrapper = React.FC<GenericWrapperProps>

const CalloutInner: GenericWrapper = ({ children, ...rest }) => (
  <Container {...rest}>
    <Box
      border="1px solid"
      borderColor="accent.dark"
      maxWidth={importantPx(featuredContentMaxWidth)}
      mx="auto"
      padding={rem(3.2, 3.2, 2)}>
      {children}
    </Box>
  </Container>
)

const Callout = styled(CalloutInner, { name: 'Block-Wrapper-Callout' })(
  ({
    theme: {
      mixins: { important },
      typography,
    },
  }: { theme: Theme }) => ({
    margin: rem(3.2, 'auto'),
    paddingLeft: important('var(--containedPaddingLeft)'),
    paddingRight: important('var(--containedPaddingRight)'),
    paddingTop: 0,
    paddingBottom: 0,
    'h1:first-of-type,h2:first-of-type,h3:first-of-type,h4:first-of-type,h5:first-of-type,h6:first-of-type ':
      {
        ...typography.variants['paragraph-semibold'],
        margin: rem(0, 0, 1.2),
      },
  }),
)

const OrderedFeatureList = styled(
  withProps('div', {
    className: 'block-full-width block-partially-contained',
  }) as unknown as React.FC<GenericWrapper>,
  { name: 'Block-Wrapper-OrderedFeatureList' },
)(
  ({
    theme: {
      mixins: { important, borderS },
      palette,
    },
  }: { theme: Theme }) => ({
    color: palette.grey.contrastText,
    backgroundColor: palette.grey[0],
    padding: rem(9.6, 2.4),
    '.MuiTypography-advanced-heading': {
      marginTop: important(0),
      marginBottom: rem(6.4),
    },
    li: {
      padding: rem(4.8),
      ...borderS(palette.primary.contrastText),
    },
  }),
)

const SourceIntroduction = styled(Container, {
  name: 'Block-Wrapper-SourceIntroduction',
})(
  ({
    theme: {
      mixins: { importantRem },
      palette,
      typography,
    },
  }: { theme: Theme }) => ({
    backgroundColor: palette.grey[5],
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: rem(8.5, 0, 5.5),
    marginTop: rem(5),
    marginBottom: rem(7),
    ...typography.variants['feature-paragraph'],
    '> *': {
      maxWidth: featuredContentMaxWidth,
      margin: 'auto',
    },
    'h1,h2,h3,h4,h5,h6': {
      ...typography.h4,
      marginTop: importantRem(0),
      marginBottom: importantRem(3.2),
    },
  }),
)

export interface SupportedBlockVariant {
  value: string
  label: string
  Component?: GenericWrapper | string
  shortLabel?: string
  title: string
  feature: string
}

export const supportedBlockVariants: SupportedBlockVariant[] = [
  {
    feature: 'block.variant.normal',
    value: 'none',
    label: '[normal]',
    title: 'Standard - No frills',
  },

  {
    feature: 'block.variant.callout-question',
    value: 'callout-question',
    label: 'Callout',
    Component: Callout,
    title: `
      Adds an accent-colored border around the block.`,
  },

  {
    feature: 'block.variant.chapter-recap',
    value: 'chapter-recap',
    label: 'Chapter Recap',
    Component: 'div',
    title: `
      These types of blocks arrange themselves horizontally if there are more
      than one back-to-back`,
  },

  {
    feature: 'block.variant.ordered-feature-list',
    value: 'ordered-feature-list',
    label: 'Ordered Feature List',
    Component: OrderedFeatureList,
    shortLabel: 'Features',
    title: `
      Adds a dark background to the block.  Any ordered list items will have
      large numerals`,
  },

  {
    feature: 'block.variant.research-link',
    value: 'research-link',
    label: 'Research Link',
    Component: 'div',
    title: `
      These types of blocks arrange themselves horizontally if there are more
      than one back-to-back`,
  },

  {
    feature: 'block.variant.source-introduction',
    value: 'source-introduction',
    label: 'Source Introduction',
    Component: SourceIntroduction,
    shortLabel: 'Intro',
    title: `
      A full-width block using a light-grey background.  All contained content
      will be center-aligned
      `,
  },
]

type WrapperProps = {
  children: React.ReactNode
  variant?: string
  'data-contenttype'?: string
}
const Wrapper: React.FC<WrapperProps> = props => {
  const { children, variant = 'none', ...rest } = props

  let { Component } =
    supportedBlockVariants.find(compose(equals(variant), get('value'))) || {}

  /**
   * TODO: (if ever scaffolds require their own variants)
   * This will need to be refactored.
   */
  if (!Component && rest['data-contenttype'] === CONTENT_TYPE_SCAFFOLD) {
    Component = withProps(Scaffold, {
      className: 'standalone-scaffold',
    }) as GenericWrapper
  }

  return Component ? (
    <Component {...omit('previewing')(props)} />
  ) : (
    <div {...omit('previewing')(rest)}>{children}</div>
  )
}

export default Wrapper
