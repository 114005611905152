import Form from 'common/formControls/Form'
import type { IDataPacket } from 'common/formControls/Form/@types/custom'
import { isEmptyString } from 'fp/strings'
import useNavigation from 'hooks/useNavigation'
import { defaultBlockFields } from 'hss/ContentBuilder/Curriculum/Block/EditorContainer'
import type React from 'react'
import { type ReactNode, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { actions as errorActions } from 'reducers/errorLog'
import { useAuthorContext } from '../AuthorProvider'

type AuthoringFormProps = {
  children: ReactNode
}

type AuthoringFormValues = {
  applicationStandardIds: string[]
  applicationStandards: string[]
  body: string | null
  instructionStandardIds: string[]
  instructionStandards: string[]
  name: string
  sharedWithSchool: boolean
  sharedWithDistrict: boolean
  tagIds: string[]
  tags: string[]
  variant: string
}

const defaultValues: AuthoringFormValues = {
  applicationStandardIds: [],
  applicationStandards: [],
  body: null,
  instructionStandardIds: [],
  instructionStandards: [],
  name: '',
  sharedWithSchool: true,
  sharedWithDistrict: true,
  tagIds: [],
  tags: [],
  variant: 'none',
}

const AuthoringForm: React.FC<AuthoringFormProps> = ({ children }) => {
  const { formProps } = useAuthorContext()
  const { navigate } = useNavigation()
  const dispatch = useDispatch()

  const handleOnBeforeSubmit = useCallback(
    (data?: IDataPacket) => {
      const values = data as AuthoringFormValues
      const { body, name } = values

      if (isEmptyString(name)) {
        dispatch(errorActions.reportError({ message: 'Name is required' }))
        navigate('#attributes')
        return false
      }

      if (isEmptyString(body)) {
        dispatch(errorActions.reportError({ message: 'Content is required' }))
        navigate('#content')
        return false
      }

      return values
    },
    [dispatch, navigate],
  )

  return (
    <Form
      actionType={actionTypes.AUTHORING_SAVE}
      defaultValues={{
        ...defaultBlockFields,
        ...defaultValues,
        ...formProps,
      }}
      values={formProps}
      name="AuthoringEditorContainer"
      onBeforeSubmit={handleOnBeforeSubmit}
      preventEnterSubmits
      style={{ maxWidth: 'unset' }}
      suppressRequiredLabel
      variant="outlined">
      {children}
    </Form>
  )
}

export default AuthoringForm
