import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

const CardFooter = ({ showDivider = false, ...props }) => (
  <Box background="red">
    {showDivider && <Divider />}
    <Stack
      className="CardFooter"
      direction="row"
      {...props}
    />
  </Box>
)

export default CardFooter
