import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import { contentShape } from 'core/shapes'
import ContentHero from 'hss/sections/Heros/ContentHero'
import { rem } from 'styling/theming/base/mixins'
import ContentHeader from '../../ContentHeader'
import ContentRestrictionControls from '../../ContentRestrictionControls'
import Menu from './Menu'

const SubPageHeroBackground = styled('div')(({ image }) => ({
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: rem(35),
  alignItems: 'center',
  display: 'flex',
}))

const Hero = ({ content }) => {
  const { uploadsMap } = content
  const { palette } = useTheme()

  return (
    <SubPageHeroBackground image={uploadsMap.image.url}>
      <Stack
        alignItems="center"
        direction="column"
        display="flex"
        height="100%"
        justifyContent="center"
        minHeight="100%"
        width="100%">
        <ContentHero
          color={palette.common.white}
          content={content}
          left={
            <ContentHeader
              content={content}
              titleProps={{ variant: 'heroHeader' }}
            />
          }
          LeftComponent="span"
          swatchsx={{
            '&::before': {
              backgroundColor: palette.cobalt[0],
            },
          }}
          heroProps={{
            sx: {
              minHeight: '100%',
              maxHeight: '100%',
              '&:before': {
                background:
                  'linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 60%)',
                opacity: 0.75,
              },
              '>div': {
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              },
            },
          }}>
          <Menu content={content} />

          <ContentRestrictionControls content={content} />
        </ContentHero>
      </Stack>
    </SubPageHeroBackground>
  )
}

Hero.propTypes = {
  content: contentShape.isRequired,
}

export default Hero
