import { compose } from 'redux'
import { isEmptyString } from './strings'
import { isDefined, isFunction, not, pipe } from './utils'

export const assertProp = (prop, value) => obj =>
  Object.prototype.hasOwnProperty.call(obj, prop) ? obj : set(prop, value)(obj)

export const deepMerge = (target, source) => {
  // not using default args because it breaks currying for some reason
  const T = target || {}
  const S = source || {}
  const output = { ...T }
  if (isObject(T) && isObject(S)) {
    Object.keys(S).forEach(key => {
      if (isObject(S[key])) {
        // biome-ignore lint/style/noNegationElse: it's fine
        if (!(key in T)) Object.assign(output, { [key]: S[key] })
        else output[key] = deepMerge(T[key], S[key])
      } else {
        Object.assign(output, { [key]: S[key] })
      }
    })
  }
  return output
}

export const deepSortObject = obj =>
  isObject(obj)
    ? Object.prototype.hasOwnProperty.call(obj, '$$typeof') // skip over component factories
      ? obj
      : Object.keys(sortObject(obj)).reduce(
          (acc, key) => ({ ...acc, [key]: deepSortObject(obj[key]) }),
          {},
        )
    : Array.isArray(obj)
      ? obj.map(deepSortObject)
      : obj

// Placed here to avoid circular dependencies between objects and utils.
// Defaulted out of the object check because many of the existing checks
// had objects with circular references.
export const equals =
  (i, checkObjects = false) =>
  j =>
    (Array.isArray(i) && Array.isArray(j)) ||
    (checkObjects && isObject(i) && isObject(j))
      ? /**
         * NOTE: if comparing built json isn't getting it done for you, try instead using:
         *
         * import compare from 'just-compare'
         * compare(A, B)
         *
         * This also work with arrays.  Test it out at https://anguscroll.com/just/just-compare
         * P.S., there are a bunch more simple, single-purpose routines within the parent repo.
         */
        JSON.stringify(i) === JSON.stringify(j)
      : i === j

// Placed here to avoid circular dependencies between objects and utils
export const notEquals = (value, checkObjects = false) =>
  pipe(equals(value, checkObjects), not)

export const filterKeyedObject = (obj, f) =>
  Object.fromEntries(Object.entries(obj).filter(([_, val]) => f(val)))

export const get =
  (path, { fallback = undefined, literal = false, delim = '.' } = {}) =>
  obj => {
    const result = literal
      ? obj[path]
      : path
          ?.split?.(delim)
          .reduce((acc, frag) => (isDefined(acc) ? acc[frag] : undefined), obj)

    return result === undefined ? fallback : result
  }

export const hasProperty = key => compose(isDefined, get(key))

export const isObject = value =>
  value === new Object(value) &&
  ['[object Array]', '[object Date]', '[object Function]'].indexOf(
    Object.prototype.toString.call(value),
  ) === -1

export const mapKeys = fn => obj =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [fn(key, value, obj)]: value,
    }),
    {},
  )

export const mapValues = fn => obj =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: fn(value, key, obj),
    }),
    {},
  )

export const merge = (target, source) => ({ ...target, ...source })

export const nullIfBlank = (field, treatAsBool) => obj => {
  const current = get(field)(obj)
  return Object.prototype.hasOwnProperty.call(obj, field)
    ? set(
        field,
        isEmptyString(current) && current !== undefined
          ? treatAsBool
            ? false
            : null
          : current,
      )(obj)
    : obj
}

export const omit =
  (...keys) =>
  (obj = {}) =>
    Object.entries(obj)
      .filter(
        isFunction(keys[0])
          ? entry => !keys[0](entry)
          : ([key]) => !keys.flat().map(String).includes(key),
      )
      .reduce((_obj, [key, val]) => Object.assign(_obj, { [key]: val }), {})

export const omitIfBlank = (field, treatAsBool) => obj => {
  const result = nullIfBlank(field, treatAsBool)(obj)
  return isDefined(result[field]) ? result : omit(field)(result)
}

export const pick =
  (...keys) =>
  obj =>
    Object.entries(obj)
      .filter(
        isFunction(keys[0])
          ? keys[0]
          : ([key]) => keys.flat().map(String).includes(key),
      )
      .reduce((_obj, [key, val]) => Object.assign(_obj, { [key]: val }), {})

export const renameKeys = (keysMap, obj) =>
  isDefined(obj)
    ? Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...{ [keysMap[key] || key]: obj[key] },
        }),
        {},
      )
    : {}

export const shallowClone = (obj, setDefault) => {
  if (obj === undefined && setDefault !== undefined) {
    return setDefault
  }
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  if (Array.isArray(obj)) {
    return [...obj]
  }

  return { ...obj }
}

export const set =
  (path, value, literal = false, flat = false, delim = '.', clone = true) =>
  obj => {
    const draft = clone ? shallowClone(obj) : obj

    let pointer = draft
    const pList = literal ? [path] : String(path).split(delim)
    const key = pList.pop()

    pList.forEach(p => {
      pointer[p] = clone ? shallowClone(pointer[p], {}) : pointer[p] || {}
      pointer = pointer[p]
    })

    pointer[key] = flat && isFunction(value) ? value(obj) : value

    return draft
  }

export const sortObject = obj => Object.fromEntries(Object.entries(obj).sort())
