import CollapsibleSection from 'common/layout/CollapsibleSection'
import {
  ApplicationStandardsPicker,
  InstructionStandardsPicker,
} from 'common/standards/StandardsPickers'
import { prefix } from 'fp/strings'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'

const Standards = ({ disabled = false, namePrefix = '', ...rest }) => {
  const prefixName = prefix(namePrefix)

  const existingStandards = useWatch({
    name: [
      prefixName('applicationStandardIds'),
      prefixName('instructionStandardIds'),
    ],
  })

  const empty = !existingStandards.flat().length

  return (
    <CollapsibleSection
      empty={empty}
      initiallyExpanded={false}
      label="Standards"
      style={{ padding: 0 }}
      {...rest}>
      <ApplicationStandardsPicker
        disabled={disabled}
        name={prefixName('applicationStandards')}
        style={{ marginTop: 0 }}
        valueField={prefixName('applicationStandardIds')}
      />

      <InstructionStandardsPicker
        disabled={disabled}
        name={prefixName('instructionStandards')}
        style={{ marginTop: 0 }}
        valueField={prefixName('instructionStandardIds')}
      />
    </CollapsibleSection>
  )
}

Standards.propTypes = {
  disabled: PropTypes.bool,
  namePrefix: PropTypes.string,
}

export default Standards
