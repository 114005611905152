import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import {
  ABILITY_ASSIGNMENT_CREATION,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
} from 'core/consts'
import { get, set } from 'fp/objects'
import routeConfig, {
  chapterChildrenRouteConfig,
} from 'hss/ContentViewer/routeConfig'
import { Routes } from 'react-router-dom'
import { configToRoutes } from 'routing/TraverseRoute'
import Edit from './Edit'

const editableRoutes = contentType => {
  const originalRoute = get(contentType)(routeConfig)
  return {
    [`${contentType}Edit`]: {
      abilityFlags: ABILITY_ASSIGNMENT_CREATION,
      path: `${originalRoute.path}/settings`,
      pinnable: false,
      element: <Edit />,
    },
    [contentType]: set('pinnable', false)(originalRoute),
  }
}

const routes = configToRoutes({
  ...routeConfig,
  pinnableChildren: false,
  chapter: {
    ...routeConfig.chapter,
    children: [
      ...Object.values(
        set('subsection.pinnable', true)(chapterChildrenRouteConfig),
      ),
      {
        abilityFlags: ABILITY_ASSIGNMENT_CREATION,
        element: <Edit />,
        path: 'settings',
      },
    ],
  },
  ...editableRoutes(CONTENT_TYPE_SOURCE),
  ...editableRoutes(CONTENT_TYPE_ECHO),
})

const AssignmentCreatorRoutes = () => (
  <ErrorBoundary moduleName="AssignmentCreatorRoutes">
    <Routes>{routes}</Routes>
  </ErrorBoundary>
)

export default AssignmentCreatorRoutes
