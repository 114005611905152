import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from 'common/navigation/links/Link'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import { contentShape } from 'core/shapes'
import { assert, alter } from 'core/store/search/squery'
import { buildUrl } from 'fp/internet'
import { pluralize } from 'fp/strings'
import { isDefined } from 'fp/utils'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { classroomAssignmentsUrl } from 'routing/consts'

const ChapterAssignmentLink = withAbilityCheck(({ content }) => {
  const { assignmentCount, id: contentId, name } = content

  const filteredAssignmentsUrl = buildUrl(classroomAssignmentsUrl, {
    squery: {
      sq: alter.set.where('contentId').is(String(contentId))(assert()),
    },
  })

  return (
    <Stack direction="row">
      {isDefined(assignmentCount) && assignmentCount > 0 ? (
        <Typography
          component="div"
          variant="card-footer">
          Used in&nbsp;
          <Link
            aria-label={`${pluralize('assignment')(
              assignmentCount,
            )} of chapter, ${name}`}
            data-testid="assignment-count-link"
            to={filteredAssignmentsUrl}>
            {pluralize('assignment')(assignmentCount)}
          </Link>
        </Typography>
      ) : (
        <Typography
          component="div"
          variant="card-footer">
          Not assigned
        </Typography>
      )}
    </Stack>
  )
}, ABILITY_ASSIGNMENT_CREATION)

ChapterAssignmentLink.propTypes = {
  content: contentShape.isRequired,
}

export default ChapterAssignmentLink
