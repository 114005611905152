import { Stack } from '@mui/material'
import CardActions from '@mui/material/CardActions'
import Icon from '@mui/material/Icon'
import LinkButton from 'common/navigation/links/LinkButton'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import { contentShape } from 'core/shapes'
import useAbilityChecker from 'hooks/useAbilityChecker'
import ClipboardChecked from 'hss/images/indicators/clipboard-checked.svg'
import { getTeacherAssignmentCreateRoute } from 'routing/consts'
import { rem } from 'styling/theming/base/mixins'

const Actions = ({ content }) => {
  const { contentType, id: contentId, name } = content
  const has = useAbilityChecker()

  return (
    <Stack padding={rem(0, 1, 0, 0)}>
      <CardActions
        disableSpacing
        sx={{ padding: '0 !important' }}>
        {!!has(ABILITY_ASSIGNMENT_CREATION) && (
          <LinkButton
            aria-label={`Assign chapter, ${name}`}
            color="secondary"
            size="small"
            to={getTeacherAssignmentCreateRoute({ contentType, id: contentId })}
            variant="secondary">
            <Icon
              sx={{
                overflow: 'visible',
                width: rem(3),
                svg: {
                  display: 'inline-block',
                  margin: rem(0, 0.8, 0, 0),
                  stroke: 'currentColor',
                },
              }}>
              <ClipboardChecked />
            </Icon>
            Assign
          </LinkButton>
        )}
      </CardActions>
    </Stack>
  )
}

Actions.propTypes = {
  content: contentShape.isRequired,
}

export default Actions
