import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import cl from 'classnames'
import ContainerGrid from 'common/layout/ContainerGrid'
import { componentShape } from 'core/shapes'
import { omit, pick } from 'fp/objects'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import Headline, { StyledHeadline } from './Headline'
import withColorSwatch from './withColorSwatch'

/**
 * These are props that should only apply to the whole container, and not to the
 * left-side Headline
 */
const layoutProps = ['gutterBottom', 'mb', 'mt', 'gap']

const SplitHeadline = props => {
  const {
    LeftComponent = StyledHeadline,
    children,
    contained = false,
    hr = false,
    left,
    leftProps,
    mb = 3,
    mt = 10,
    right,
    rightProps,
    swatch = false,
    swatchsx,
    ...rest
  } = props

  const assertedProps = {
    ...props,
    mb,
    mt,
    swatch,
    contained,
    hr,
  }

  /**
   * Looks a bit convoluted, but makes sense if you walk through it logically. The
   * outer shell is itself a Headline, but renders as a ContainerGrid.  It's a
   * Headline in order for any children to continue to respect the heading level.
   *
   * The Grid itself is actually a StyledHeadline (does not count against nesting
   * level) that renders as a wrapping div.  It's a StyledHeadline so that it
   * receives properties that affect layout or decorations (and only those).
   *
   * The left side is the only thing that truly renders as a heading (h1, etc) and
   * it omits any of the layout props used by the outer shell.
   *
   * Some vertical margin between the left and right sections is applied if the
   * screen is small enough that they need to stack.
   *
   */

  const ChildrenContainer = contained ? Container : Box
  const leftRef = useRef()
  const ChildrenWrapper = swatch
    ? withColorSwatch(ChildrenContainer, leftRef)
    : ChildrenContainer

  return (
    <Headline
      component={
        <ChildrenWrapper
          className={cl({ 'container-headline--hr': hr })}
          sx={{ ...swatchsx, ...pick(layoutProps)(assertedProps) }}>
          <ContainerGrid
            {...omit(layoutProps)(rest)}
            sx={{ gap: { xs: 2, md: 0 } }}>
            <LeftComponent
              ref={leftRef}
              {...leftProps}>
              {left}
            </LeftComponent>

            {right}
          </ContainerGrid>
        </ChildrenWrapper>
      }>
      {Boolean(children) && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Headline>
  )
}

SplitHeadline.propTypes = {
  children: componentShape,
  contained: PropTypes.bool,
  hr: PropTypes.bool,
  LeftComponent: PropTypes.elementType,
  left: componentShape.isRequired,
  leftProps: PropTypes.object,
  mb: PropTypes.number,
  mt: PropTypes.number,
  right: componentShape.isRequired,
  rightProps: PropTypes.object,
  swatch: PropTypes.bool,
  swatchsx: PropTypes.object,
}

export default SplitHeadline
