import List from '@mui/material/List'
import TextField from 'common/formControls/textInputs/TextField'
import { arraySequence } from 'fp/arrays'
import { ordinalName, titleCase } from 'fp/strings'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const InputLabelProps = { shrink: true, margin: 'dense' }

const TextFields = ({ numPhrases, phrases, previewing = false, onChange }) => {
  const { isGrading } = useContext(interactiveContext)

  return (
    <List>
      {arraySequence(numPhrases).map(i => (
        <li key={`phrase${i}`}>
          <TextField
            disabled={isGrading}
            InputLabelProps={InputLabelProps}
            inputProps={{
              'data-cardinal': i,
            }}
            label={
              numPhrases === 1
                ? 'Word or phrase'
                : titleCase(`${ordinalName(i + 1)} choice`)
            }
            name={`phrase${i}`}
            onChange={onChange}
            required={!previewing}
            value={phrases?.[i] || ''}
          />
        </li>
      ))}
    </List>
  )
}

TextFields.propTypes = {
  numPhrases: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  phrases: PropTypes.array.isRequired,
  previewing: PropTypes.bool,
}

export default TextFields
