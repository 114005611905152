import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import { INTERACTION_TYPE_NOTEBOOK } from 'core/consts'
import useEffectOnce from 'hooks/useEffectOnce'
import useReduxCallback, {
  BUSY,
  ERROR,
  IDLE,
  SUCCESS,
} from 'hooks/useReduxCallback'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { failure, success } from 'sagas/utils'
import { getViewerTopLevelContent } from 'selectors/contentViewer'
import { getInteractionByType } from 'selectors/interactions'
import { getUserAssignment } from 'selectors/userAssignments'
import Pane from './Pane'

const statusMessage = {
  [IDLE]: '',
  [BUSY]: 'Saving notes…',
  [SUCCESS]: 'Notes saved.',
  [ERROR]: 'Error saving notes.',
}

const resolve = props => {
  const {
    passThrough: {
      action: { interactionType } = {},
    } = {},
    type,
  } = props || {}
  return (
    type === success(actionTypes.INTERACTION_FETCH_LIST) &&
    interactionType === INTERACTION_TYPE_NOTEBOOK
  )
}

const reject = props => {
  const {
    passThrough: {
      action: { interactionType } = {},
    } = {},
    type,
  } = props || {}
  return (
    type === failure(actionTypes.INTERACTION_FETCH_LIST) &&
    interactionType === INTERACTION_TYPE_NOTEBOOK
  )
}

const Notebook = ({ hideHeader = false }) => {
  const [saveDispatch, saveStatus] = useReduxCallback({
    actionType: actionTypes.INTERACTION_POST,
    allowParallel: true,
  })

  const { id: contentId, id: contextContentId } =
    useSelector(getViewerTopLevelContent) || {}
  const { id: userAssignmentId } = useSelector(getUserAssignment) || {}
  const [showStatus, setShowStatus] = useState()
  const [notes, setNotes] = useState('')

  const onFetchSuccess = data => {
    setNotes(data?.response?.data[0]?.interactionData?.value || '')
  }

  const [fetchDispatch, fetchStatus] = useReduxCallback({
    actionType: actionTypes.INTERACTION_FETCH_LIST,
    onSuccess: onFetchSuccess,
    resolve,
    reject,
  })

  const notebookInteraction = useSelector(
    getInteractionByType({
      type: INTERACTION_TYPE_NOTEBOOK,
      userAssignmentId,
    }),
  )

  const handleChange = ({ target: { value } }) => {
    setNotes(value)
    saveDispatch({
      contentId,
      contextContentId,
      interactionData: { value },
      interactionType: INTERACTION_TYPE_NOTEBOOK,
      userAssignmentId,
      id: notebookInteraction?.id,
    })
  }

  const Header = useMemo(
    () =>
      hideHeader
        ? ({ children }) => <div>{children}</div>
        : ({ children }) => <Pane title="Notebook">{children}</Pane>,
    [hideHeader],
  )

  useEffect(() => {
    if (saveStatus !== IDLE) {
      setShowStatus(true)
    }
  }, [saveStatus])

  useEffectOnce(() => {
    fetchDispatch({
      interactionType: INTERACTION_TYPE_NOTEBOOK,
      userAssignmentId,
    })
  })

  return (
    <Header>
      {fetchStatus === SUCCESS ? (
        <RichTextEdit
          excludeToolButtons={['Superscript', 'Subscript', 'Link', 'Highlight']}
          onChange={handleChange}
          placeholder="Add notes here…"
          showStatus
          status={statusMessage[saveStatus]}
          features={{
            'typography.header-one': true,
            'typography.header-two': true,
            'typography.blockquote': true,
            'typography.definition': true,
          }}
          sx={{
            '.public-DraftEditorPlaceholder-root': {
              width: 'auto',
            },
            '.draft-statusbar > div': {
              opacity: showStatus ? 1 : 0,
              transition: 'all 250ms linear',
            },
          }}
          value={notes}
        />
      ) : (
        <p>Loading notes...</p>
      )}
    </Header>
  )
}

Notebook.propTypes = {
  hideHeader: PropTypes.bool,
}

export default Notebook
