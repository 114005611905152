import type { SelectChangeEvent } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import HookFormSelect from 'common/formControls/selects/HFSelect'
import Select from 'common/formControls/selects/Select'
import { filter } from 'fp/arrays'
import { isDefined } from 'fp/utils'
import {
  type SupportedBlockVariant,
  supportedBlockVariants,
} from 'hss/ContentViewer/Chapter/Block/Wrapper'

const DEFAULT_FEATURES: Record<string, boolean> = {
  'block.variant.normal': true,
  'block.variant.callout-question': true,
  'block.variant.chapter-recap': true,
  'block.variant.ordered-feature-list': true,
  'block.variant.research-link': true,
  'block.variant.source-introduction': true,
}

interface PropTypes {
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void
  value?: string
  features?: Record<string, boolean>
}

const BlockVariantPicker = (props: PropTypes) => {
  const { onChange, value, features = DEFAULT_FEATURES } = props

  const isControlled: boolean = isDefined(onChange)

  const Control = isControlled ? Select : HookFormSelect

  const filteredVariants: SupportedBlockVariant[] = filter(
    ({ feature }: { feature: string }) => features[feature],
  )(supportedBlockVariants)

  return (
    <Control
      label="Variant"
      name="variant"
      defaultValue={isControlled ? filteredVariants[0].value : undefined}
      renderValue={selected => {
        const item = filteredVariants.find(
          ({ value: itemValue }) => itemValue === selected,
        )
        return item ? item.label : ''
      }}
      {...(isControlled
        ? {
            onChange,
            value,
          }
        : null)}>
      {filteredVariants.map(({ value: itemValue, label, title }) => (
        <MenuItem
          key={label}
          sx={{ display: 'block', maxWidth: 500, whiteSpace: 'normal' }}
          value={itemValue}>
          <Typography
            className="detailed-menuitem-label"
            variant="nav-item-semibold">
            {label}
          </Typography>
          <Typography className="detailed-menuitem-title">{title}</Typography>
        </MenuItem>
      ))}
    </Control>
  )
}

export default BlockVariantPicker
