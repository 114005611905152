import SplitHeadline from 'common/text/SplitHeadline'
import { componentShape, contentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import BaseHero from './BaseHero'

const ContentHero = ({ children, content, heroProps, sx, title, ...rest }) => (
  <BaseHero
    content={content}
    sx={sx}
    {...heroProps}>
    <SplitHeadline
      contained
      left={title || content?.name}
      mt={4}
      right={<div>{children}</div>}
      rightProps={{ style: { textAlign: 'right' } }}
      swatch
      {...rest}
    />
  </BaseHero>
)

ContentHero.propTypes = {
  children: componentShape,
  content: contentShape,
  heroProps: PropTypes.object,
  title: componentShape,
  sx: PropTypes.object,
}

export default ContentHero
