import Button from '@mui/material/Button'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { forwardRef, useContext } from 'react'
import { Send } from 'react-feather'
import { interactiveContext } from './InteractiveProvider'

const SubmitButton = forwardRef(
  ({ children = 'Submit Your Answer', disabled = false, ...rest }, ref) => {
    const {
      completed,
      interactive: { contentSubType } = {},
      markComplete,
      submitEnabled = true,
    } = useContext(interactiveContext)

    return (
      <Button
        data-testid={`${contentSubType}-submit-button`}
        disabled={completed || disabled || !submitEnabled}
        startIcon={<Send />}
        {...rest}
        onClick={markComplete}
        ref={ref}
        variant="primary">
        {children}
      </Button>
    )
  },
)

SubmitButton.propTypes = {
  children: componentShape,
  disabled: PropTypes.bool,
}

export default SubmitButton
