import {
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_WORD_CLOUD,
} from 'core/consts'
import withProps from 'hoc/withProps'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
} from 'hss/ContentBuilder/consts'
import FeaturePoll from 'hss/ContentViewer/Chapter/Echo/Features/Panels/FeaturePoll'
import CheckCircle from 'hss/images/indicators/check-closed-circle.svg'
import FullCircle from 'hss/images/indicators/full-circle.svg'
import HalfCircle from 'hss/images/indicators/half-circle.svg'
import { Circle, HelpCircle, XCircle } from 'react-feather'
import LinearScale from '../../interactives/LinearScale'
import Poll from '../../interactives/Poll'
import VennDiagram from '../../interactives/VennDiagram'
import WordCloud from '../../interactives/WordCloud'
import withQuestionPrompt from '../withQuestionPrompt'
import ActivityRubricAggregate from './AggregateViews/ActivityRubricAggregate'
import EvaluationRankingAggregate from './AggregateViews/EvaluationRankingAggregate'
import GroupAndSortAggregate from './AggregateViews/GroupAndSortAggregate'
import MultipleChoiceAggregate from './AggregateViews/MultipleChoiceAggregate'
import ScoreTable from './AggregateViews/ScoreTable'
import TextResponseRubricAggregate from './AggregateViews/TextResponseRubricAggregate'
import ChartCompletionAnswerKey from './AnswerKeyViews/ChartCompletionAnswerKey'
import ActivityGrader from './Graders/ActivityGrader'
import ChartCompletionGrader from './Graders/ChartCompletionGrader'
import EchoResponseRubricGrader from './Graders/EchoResponseRubricGrader'
import ManualOverrideGrader from './Graders/ManualOverrideGrader'
import StudentResponseRubricGrader from './Graders/StudentResponseRubricGrader'
import VennDiagramRubricGrader from './Graders/VennDiagramRubricGrader'

/**
 * For some interactive types, it's simple enough to call `useIsInAnswerKeyContext` and tweak the UI for the answer key.
 * But for the following interactive types, it's simpler to provide a whole separate component for the answer key view.
 */
export const getRegisteredAnswerKeyViews = () => ({
  [INTERACTIVE_TYPE_CHART_COMPLETION]: ChartCompletionAnswerKey,
})

export const getRegisteredInteractiveGraders = () => ({
  [ECHO_FEATURE_BLASTY_BLAST]: EchoResponseRubricGrader,
  [ECHO_FEATURE_PICTURE_THIS]: EchoResponseRubricGrader,
  [ECHO_FEATURE_TALK_BACK]: EchoResponseRubricGrader,
  [INTERACTIVE_TYPE_ACTIVITY]: ActivityGrader,
  [INTERACTIVE_TYPE_CHART_COMPLETION]: ChartCompletionGrader,
  [INTERACTIVE_TYPE_GROUP_AND_SORT]: ManualOverrideGrader,
  [INTERACTIVE_TYPE_MULTIPLE_CHOICE]: ManualOverrideGrader,
  [INTERACTIVE_TYPE_ESSAY]: StudentResponseRubricGrader,
  [INTERACTIVE_TYPE_EVALUATION_RANKING]: ManualOverrideGrader,
  [INTERACTIVE_TYPE_IMAGE_TITLE_DRAG]: ManualOverrideGrader,
  [INTERACTIVE_TYPE_VENN_DIAGRAM]: VennDiagramRubricGrader,
})

export const getRegisteredAggregateViews = () => ({
  [ECHO_FEATURE_POLL]: { Component: FeaturePoll },
  [INTERACTIVE_TYPE_ACTIVITY]: {
    scoreableOnly: true,
    Component: withQuestionPrompt(ActivityRubricAggregate),
  },
  [INTERACTIVE_TYPE_CHART_COMPLETION]: {
    Component: withQuestionPrompt(ScoreTable),
    scoreableOnly: true,
    showAverageScore: true,
  },
  [INTERACTIVE_TYPE_ESSAY]: {
    Component: withQuestionPrompt(TextResponseRubricAggregate),
    scoreableOnly: true,
  },
  [INTERACTIVE_TYPE_EVALUATION_RANKING]: {
    Component: withQuestionPrompt(EvaluationRankingAggregate),
  },
  [INTERACTIVE_TYPE_GROUP_AND_SORT]: {
    Component: withQuestionPrompt(GroupAndSortAggregate),
    showAverageScore: true,
  },
  [INTERACTIVE_TYPE_IMAGE_TITLE_DRAG]: {
    scoreableOnly: true,
    Component: withQuestionPrompt(ScoreTable),
  },
  [INTERACTIVE_TYPE_LINEAR_SCALE]: {
    Component: withProps(LinearScale, { displayResults: true }),
  },
  [INTERACTIVE_TYPE_MULTIPLE_CHOICE]: {
    Component: MultipleChoiceAggregate,
    showAverageScore: true,
  },
  [INTERACTIVE_TYPE_POLL]: { Component: Poll },

  [INTERACTIVE_TYPE_VENN_DIAGRAM]: {
    Component: withProps(VennDiagram, { displayResults: true }),
  },
  [INTERACTIVE_TYPE_WORD_CLOUD]: {
    Component: withProps(WordCloud, { displayResults: true }),
  },
})

export const fullWidthAggregates = [
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
]

export const gradeIndicators = {
  correct: {
    color: 'green',
    Icon: CheckCircle,
    label: 'Correct',
  },
  incorrect: {
    color: 'red',
    Icon: XCircle,
    label: 'Incorrect',
  },
  gradingRequired: {
    Icon: HelpCircle,
    label: 'Grading required',
  },
  incomplete: {
    Icon: HalfCircle,
    label: 'Incomplete',
  },
  complete: {
    Icon: FullCircle,
    label: 'Complete',
  },
  notStarted: {
    Icon: Circle,
    label: 'Not started',
  },
}
