import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import withContainerQuerySize from 'hoc/withContainerQuerySize'
import withContent from 'hoc/withContent'
import { formProps } from 'hss/ContentBuilder/utils'
import CourseBg from 'hss/images/content/course-bg.png'
import Hero from './Hero'
import Unit from './Unit'

const Background = styled('div')(
  ({
    theme: {
      mixins: { rem, flexWidth },
      palette,
    },
  }) => ({
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${CourseBg})`,
    backgroundSize: '100%',
    marginTop: rem(2.4),
    '.course-grid-container': {
      padding: rem(1.4, 0, 6.4, 0),
    },
    '.MuiGrid-item.course-grid': {
      margin: 0,
      padding: rem(5.4, 0, 0, 2.4),
    },
    '.course-grid[data-containersize="md"] .MuiGrid-item, .course-grid[data-containersize="md"] .MuiGrid-item.MuiGrid-grid-lg-8.MuiGrid-grid-xl-9':
      flexWidth('50%'),
    '.course-grid[data-containersize="lg"] .MuiGrid-item':
      flexWidth('33.333333%'),
    '.course-grid[data-containersize="lg"] .MuiGrid-item.MuiGrid-grid-lg-8.MuiGrid-grid-xl-9':
      flexWidth('66.666666%'),

    '.course-grid[data-containersize="xl"] .MuiGrid-item': flexWidth('25%'),
    '.course-grid[data-containersize="xl"] .MuiGrid-item.MuiGrid-grid-lg-8.MuiGrid-grid-xl-9':
      flexWidth('75%'),
    '.course-grid[data-containersize="lg"] .MuiButton-course-button': {
      width: '48%',
    },
    '.course-grid[data-containersize="lg"] .MuiButton-course-button:nth-child(odd)':
      {
        margin: rem(0, 1.2, 2.4, 0),
      },
    '.course-grid[data-containersize="lg"] .MuiButton-course-button:nth-child(even)':
      {
        margin: rem(0, 0, 2.4, 1.2),
      },
    '.course-grid[data-containersize="xl"] .MuiButton-course-button': {
      margin: rem(0, 2.4, 2.4, 0),
    },
    '.course-grid[data-containersize="xl"] .MuiButton-course-button:nth-child(3)':
      {
        margin: rem(0, 0, 2.4, 0),
      },
    '.course-grid[data-containersize="xs"] .unit-title, .course-grid[data-containersize="sm"] .unit-title':
      {
        width: '100%',
      },
    '.course-grid[data-containersize="md"] .unit-title': {
      width: '80%',
    },
    '.course-grid[data-containersize="lg"] .unit-title': {
      width: '85%',
    },
    '.course-grid[data-containersize="xl"] .unit-title': {
      width: '90%',
    },
    '.MuiPaper-root.MuiPaper-vertical-grid.MuiPaper-rounded.MuiCard-root': {
      background: 'none',
    },
    '.MuiPaper-root.MuiPaper-vertical-grid.MuiPaper-rounded.MuiCard-root .MuiStack-root':
      {
        background: palette.background.default,
      },
  }),
)

const CustomGrid = withContainerQuerySize(Grid)

const Units = ({ content }) => {
  const { children } = content

  return (
    <>
      <Hero content={content} />
      <Background>
        <Grid
          className="course-grid-container"
          container
          spacing={3}>
          {children.map(unit => (
            <CustomGrid
              alignItems="flex-end"
              className="course-grid"
              container
              direction="column"
              item
              key={unit.id}
              xs={12}>
              <Unit
                contentId={unit.id}
                contentLength={content.length}
              />
            </CustomGrid>
          ))}
        </Grid>
      </Background>
    </>
  )
}

Units.propTypes = formProps

const UnitsWithContent = withContent(Units, {
  contentType: CONTENT_TYPE_COURSE,
  queryParams: { childDepth: 2 },
})

export default UnitsWithContent
