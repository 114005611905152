import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Link from 'common/navigation/links/Link'
import CardTitle from 'common/text/CardTitle'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { contentShape } from 'core/shapes'
import { noop } from 'fp/utils'
import withProps from 'hoc/withProps'
import useContent from 'hooks/useContent'
import Card from 'hss/views/Card'
import PosterImage from 'hss/views/Card/PosterImage'
import { buildContentUrl } from 'projections/content'
import PropTypes from 'prop-types'
import { rem } from 'styling/theming/base/mixins'
import Footer from './Footer'

const ContentRenderer = ({ content }) => {
  const {
    data: { subtitle },
    name,
  } = content
  const { palette } = useTheme()

  return (
    <Stack
      justifyContent="space-between"
      flexDirection="column"
      border="1px solid"
      borderColor={palette.border[1]}
      alignItems="stretch"
      borderTop="none"
      display="flex"
      color={palette.text.primary}
      height="100%"
      sx={{ flex: 1 }}>
      <Link
        sx={{
          fontWeight: '550',
          fontSize: rem(1),
          padding: rem(0, 0, 3.2),
          margin: 0,
          height: 'auto',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        to={buildContentUrl()(content)}>
        <CardHeader
          disableSpacing
          title={
            <CardTitle
              eyebrow={subtitle}
              title={name}
            />
          }
        />
      </Link>
      <Footer content={content} />
    </Stack>
  )
}

ContentRenderer.propTypes = {
  content: contentShape.isRequired,
}

const ChapterCard = ({ contentId }) => {
  const content = useContent({ contentType: CONTENT_TYPE_CHAPTER, contentId })

  const Renderer = withProps(Card, {
    content,
    contentCreator: false,
    ContentRenderer,
    handlePreview: noop,
    ImageRenderer: withProps(PosterImage, {
      includeLinkToContent: true,
      viewMode: 'list',
    }),
    listModeDirection: 'rtl',
    listModeImageAspectRatio: '1/1',
    viewMode: 'grid',
  })
  return <Renderer />
}

ChapterCard.propTypes = {
  contentId: PropTypes.string.isRequired,
}

export default ChapterCard
