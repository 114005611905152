// Definitions
const regexToPattern = (regex) => regex.toString().slice(1, -1);

const bigIntRegex = /^-{0,1}\d{1,16}$/;
const positiveBigIntRegex = /^\d{1,16}$/;
const emailRegex = /^.+@.+\..+$/;

// JSON Schema Types
export const bigInt = {
  type: "string",
  pattern: regexToPattern(bigIntRegex),
};

export const url = {
  type: "string",
  pattern: "^(https://|/).+$",
};

export const datetime = {
  type: "string",
  pattern: "^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z$",
};

export const datetimeOrDate = {
  type: "string",
  pattern: "^\\d{4}-\\d{2}-\\d{2}(T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z){0,1}$",
};

export const email = {
  type: "string",
  pattern: regexToPattern(emailRegex),
};

// Helpers
export const isBigInt = (input) => bigIntRegex.test(input);
export const isPositiveBigInt = (input) => positiveBigIntRegex.test(input);
export const isEmail = (input) => emailRegex.test(input);
