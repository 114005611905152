import { Box } from '@mui/material'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import Standards from 'common/standards/Standards'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import BlockVariantPicker from 'hss/ContentBuilder/Curriculum/BlockVariantPicker'
import ImageControls from 'hss/ContentBuilder/Curriculum/ImageControls'
import { useAuthorContext } from '../AuthorProvider'

const AttributesPanel = () => {
  const { formProps } = useAuthorContext()

  return (
    <Box mr={3}>
      <HFTextField
        name="name"
        required
      />

      <BlockVariantPicker
        features={{
          'block.variant.normal': true,
          'block.variant.callout-question': true,
        }}
      />

      <HeadlineStyleOffset offset={4}>
        <ImageControls
          captionPosition="bottom"
          formProps={formProps}
          label="Banner Image"
          labelProps={{ mb: 2 }}
          showMetaData
          thumb
        />

        <Standards />

        {/* <SharingControls /> */}
      </HeadlineStyleOffset>
    </Box>
  )
}

export default AttributesPanel
