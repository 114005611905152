import Add from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import LinkButton from 'common/navigation/links/LinkButton'
import {
  ABILITY_CONTENT_CREATION,
  CONTENT_STATE_DRAFT,
  CONTENT_TYPE_COURSE,
} from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import CurriculumHero from 'hss/images/content/curriculum-bg.webp'
import ContentHero from 'hss/sections/Heros/ContentHero'
import { contentBuilderUrl } from 'routing/consts'
import { px } from 'styling/theming/base/mixins'

const content = {
  academicVocabPhraseIds: [],
  academicVocabPhrases: [],
  applicationStandardIds: [],
  applicationStandards: [],
  contentState: CONTENT_STATE_DRAFT,
  contentType: CONTENT_TYPE_COURSE,
  contentVocabPhraseIds: [],
  contentVocabPhrases: [],
  footnoteVocabPhraseIds: [],
  footnoteVocabPhrases: [],
  id: 'NA',
  instructionStandardIds: [],
  instructionStandards: [],
  tagIds: [],
  tags: [],
  uploadsMap: {
    full: {
      url: 'https://s3.us-east-1.amazonaws.com/staging-content.studysync.com/hssdev/2022/3/8/17-75370120.jpg',
    },
  },
  vocabContent: [],
  vocabContentIds: [],
}

const PageHeroBackground = styled('div')(
  ({
    theme: {
      mixins: { rem },
    },
    image,
  }) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: rem(8),
    height: rem(31.8),
  }),
)

export const AddButton = withAbilityCheck(
  () => (
    <LinkButton
      color="secondary"
      startIcon={<Add />}
      to={`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_COURSE}/create`}
      variant="secondary">
      Add Course
    </LinkButton>
  ),
  ABILITY_CONTENT_CREATION,
)

const Hero = () => (
  <PageHeroBackground image={CurriculumHero}>
    <ContentHero
      color="grey.5"
      content={content}
      heroProps={{ sx: { height: px(150) } }}
      title={
        <div>
          Curriculum
          <br />
          <Typography
            component="div"
            fontWeight={300}
            mt={1}
            variant="h4">
            All Courses
          </Typography>
        </div>
      }
    />
  </PageHeroBackground>
)

export default Hero
