import {
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
} from 'core/consts'
import { get } from 'fp/objects'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getParentOfContentByType } from 'selectors/contentViewer'

const useCardContent = ({ lastViewedContentId }, content, isOpen) => {
  const { contentType, data, id } = content || {}
  const subtitle = get('subtitle')(data)

  const { name: parentCourseName } = useSelector(
    getParentOfContentByType({
      contentId: id,
      contentType,
      parentContentType: CONTENT_TYPE_COURSE,
    }),
  ) || { name: '' }

  // Space in between parent.parent.name and subtitle should only appear if the name AND subtitle are present
  const chapterBlurb = `${parentCourseName || ''}${parentCourseName && subtitle ? ' ' : ''}${subtitle || ''}`

  // Memoizing so we don't spam the console with endless warnings
  const cardContent = useMemo(() => {
    const assignmentCardContent = (cardContentProp, title) => ({
      cardContent: get(cardContentProp)(content) ?? '',
      cardContentType: contentType,
      callToAction: isOpen
        ? lastViewedContentId
          ? 'Continue'
          : 'Start Assignment'
        : 'View Assignment',
      cardTitle: title,
    })

    let result = get(contentType)({
      // In case the parent.parent.name AND subtitle (optional field) are both blank, just show contentType
      [CONTENT_TYPE_CHAPTER]: assignmentCardContent(
        'data.blurb',
        chapterBlurb ? chapterBlurb : contentType,
      ),
      [CONTENT_TYPE_SOURCE]: assignmentCardContent(
        'data.assetBlurb',
        contentType,
      ),
      [CONTENT_TYPE_ECHO]: assignmentCardContent(
        'data.assetBlurb',
        contentType,
      ),
      [CONTENT_TYPE_ASSESSMENT]: {
        cardContent: get('data.blurb')(content) ?? '',
        cardContentType: 'test',
        callToAction: isOpen
          ? lastViewedContentId
            ? 'Continue'
            : 'Start Test'
          : 'View Test',
        cardTitle: 'test',
      },
    })

    if (!result) {
      result = {
        cardContent:
          'This assignment has an invalid content type and may not work.',
        callToAction: 'Open Anyway',
      }

      // biome-ignore lint/suspicious/noConsole: intended
      console.log(`Unrecognized assignment contentType \`${contentType}\`.`)
    }

    return result
  }, [chapterBlurb, content, contentType, isOpen, lastViewedContentId])

  return cardContent
}

export default useCardContent
